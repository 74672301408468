@import "../../scss/abstarct/colors";

.menuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 5rem;
  position: relative;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
  &:active {
    &::before {
      transform: scale(0);
      opacity: 0.4;
      transition: all 0s;
    }
  }
  &:focus {
    outline: none;
    background-color: $color-dark-black;
  }
  .icon {
    margin-right: 0.7rem;
    color: $color-white;
    height: 1.5rem;
  }
  a {
    color: inherit;
  }
  .lable {
    color: $color-white;
  }
  &::before {
    content: "";
    width: 5rem;
    height: 5rem;
    transform: scale(1.5);
    position: absolute;
    top: 0.5rem;
    background-color: $color-dark-gray;
    opacity: 0;
    border-radius: 50%;
    z-index: -1;
    transition: all 0.5s;
  }
}
.selected {
  .icon {
    transition: 0.3s all ease-in-out;
    color: $color-gloden;
  }
  .lable {
    color: $color-gloden;
  }
}

@media screen and (max-width: 800px) {
  .menuItem {
    flex-direction: column;
    .icon {
      height: 1.3rem;
      width: auto;
      margin-right: unset;
      margin-bottom: 0.5rem;
    }
  }
}
