@import "../../scss/abstarct/colors";
.editBtn {
  padding: 0px;
  margin-bottom: 1rem;
  width: fit-content;
  border-style: none;
  background-color: transparent;
  img {
    pointer-events: none;
  }
}
.signOutBtn {
  margin-top: 1.5rem;
  width: fit-content;
  background-color: transparent;
  border: 1px solid $color-black;
  font-family: le-monde-livre-std, "Times New Roman", Times, serif;
  font-style: normal;
  font-weight: 300;
  &:hover,
  &:focus {
    background-color: $color-black;
    color: $color-white;
    outline: none;
  }
}
