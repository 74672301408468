@import '../../scss/abstarct/colors';

.container {
  position: relative;
  display: flex;
  flex: 1 0 17rem;
  max-width: 22rem;
  flex-direction: column;
  justify-content: flex-start;
  width: 16rem;
  margin-right: 2rem;
  .exp {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 0.3rem;
    font-size: 0.8rem;
    z-index: 1;
    color: $color-white;
    background-color: rgba($color: $color-black, $alpha: 0.5);
  }
  .imageContainer {
    min-height: 11rem;
    background-color: $color-light-gray;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    overflow: hidden;

    &::before,
    &::after {
      content: '';
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 50%;
      position: absolute;
      top: calc(50% - 0.75rem);
      background-color: $color-white;
    }
    &::before {
      left: 0rem;
      transform: translateX(-0.75rem);
    }
    &::after {
      right: 0rem;
      transform: translateX(0.75rem);
    }
  }
  .content {
    min-height: 7rem;
    padding: 1rem;
    flex-grow: 1;
    background-color: $color-light-gray;
    .title {
      margin-top: 0px;
      margin-bottom: 0.5rem;
      color: $color-gloden-dark;
    }
  }
}

.placeholder {
  position: relative;
  display: flex;
  flex: 1 0 17rem;
  max-width: 22rem;
  flex-direction: column;
  justify-content: flex-start;
  width: 16rem;
  margin-right: 2rem;
  background-color: $color-light-gray;

  .imageContainer {
    min-height: 11rem;
    background-color: rgb(240, 240, 240);
    position: relative;
    overflow: hidden;
    &::before,
    &::after {
      content: '';
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 50%;
      position: absolute;
      top: calc(50% - 0.75rem);
      background-color: $color-white;
    }
    &::before {
      left: 0rem;
      transform: translateX(-0.75rem);
      z-index: 1;
    }
    &::after {
      right: 0rem;
      transform: translateX(0.75rem);
    }
    .animatedBackground {
      animation-duration: 1.5s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: shining;
      animation-timing-function: linear;
      background: linear-gradient(
        to right,
        rgba(255, 0, 0, 0) 0%,
        rgba(202, 202, 202, 0.431) 50%,
        rgba(255, 0, 0, 0) 100%
      );
      height: 100%;
      width: 90%;
      position: absolute;
    }
  }
  .content {
    min-height: 7rem;
    padding: 1rem;
    flex-grow: 1;
    background-color: $color-light-gray;
  }
}

@keyframes shining {
  0% {
    transform: translateX(-130%);
  }
  100% {
    transform: translateX(130%);
  }
}
