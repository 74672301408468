@import '../../scss/abstarct/colors';

.card {
  display: flex;
  flex-direction: row;
  height: min-content;
  flex: 0 0 content;
  min-height: 5rem;
  padding: 1.5rem;
  margin-right: 1rem;
  border-color: $color-black;
  border-style: solid dashed solid solid;
  border-width: 1px 2px 1px 1px;

  .discount {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 0 content;
    margin-right: 1rem;
    padding-right: 1rem;
    border-right: 1px solid $color-dark-gray;
    .value {
      font-size: 2.2rem;
      margin: auto;
      font-family: le-monde-livre-std, 'Times New Roman', Times, serif;
    }
    .expiration {
      font-size: 0.8rem;
      min-width: fit-content;
    }
  }
  .description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
    .type {
      font-size: 1rem;
      font-weight: 300;
      font-family: 'Roboto', sans-serif;
      margin: auto;
      color: $color-dark-gray;
    }
    .comment {
      font-weight: 300;
    }
  }
}

.placeholder {
  width: 15rem;
  height: 8rem;
  position: relative;
  background-color: $color-light-gray;
  overflow: hidden;
  margin-right: 1rem;
  background-image: url(./images/percentageIcon.svg);
  background-position: center;
  background-repeat: no-repeat;
  .animatedBackground {
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shining;
    animation-timing-function: linear;
    background: linear-gradient(
      to right,
      rgba(255, 0, 0, 0) 0%,
      rgba(195, 195, 195, 0.1) 50%,
      rgba(255, 0, 0, 0) 100%
    );
    height: 100%;
    width: 90%;
    position: absolute;
  }
}

@keyframes shining {
  0% {
    transform: translateX(-130%);
  }
  100% {
    transform: translateX(130%);
  }
}

@media only screen and (max-width: 768px) {
  .type {
    font-size: 0.9rem;
  }
}
