@import '../../scss/abstarct/colors';

.visits {
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 100%;
  margin: 0px;
  .visit {
    display: flex;
    opacity: 0;
    align-items: center;
    justify-content: flex-start;
    padding: 1.2rem 0px;
    list-style-type: none;
    animation: fadein 250ms ease-out 1 forwards;
    &:first-child {
      padding-top: 0;
    }
    .icon {
      flex: 0 0 auto;
      height: 1.5rem;
      margin-right: 1rem;
    }
    .visitService {
      margin: 0px;
    }
    .visitData {
      margin: 0px;
      margin-left: auto;
    }
    &:not(:last-child) {
      border-bottom: 1px dashed $color-dark-gray;
    }
  }
}

.emptyMsg {
  color: $color-dark-gray;
  margin-bottom: 2rem;
}

@keyframes fadein {
  from {
    transform: translateY(0.4rem);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
