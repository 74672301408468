@import '../../scss/abstarct/colors';
.signInForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 25rem;
  p {
    margin-top: 0;
    margin-bottom: 2rem;
    width: 100%;
  }
  .label {
    margin-bottom: 1rem;
  }
  .sendSet {
    width: 100%;
    display: flex;
    margin-bottom: 0.5rem;
    input[type='text'] {
      margin-right: 1rem;
      flex: 1 1 20rem;
    }
  }
}
