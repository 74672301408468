@import '../../scss/abstarct/colors';

.name {
  display: inline-flex;
  align-items: center;
  margin-right: 0.5rem;
  padding: 1rem 0rem;
  min-height: 2rem;
  flex-wrap: wrap;
  h1 {
    margin: 0;
  }
  .lable {
    font-size: 1rem;
    margin-right: 0.8rem;
    color: $color-dark-gray;
  }
  .text {
    font-family: 'Roboto', sans-serif;
  }
  .editBtn,
  .okBtn,
  .cancelBtn {
    padding: 0px;
    margin-left: 0.3rem;
    border-style: none;
    background-color: transparent;
    img {
      pointer-events: none;
    }
  }
  .editBtn {
    opacity: 0.8;
    margin-left: 0.3rem;
    &:hover {
      opacity: 1;
    }
  }
  .okBtn,
  .cancelBtn {
    margin-left: 0.7rem;
  }
  input[type='text'] {
    min-height: 2rem;
    padding: 0px 0.5rem;
    margin-right: 0.5rem;
    max-width: fit-content;
  }
}

.hidden {
  display: none;
}
