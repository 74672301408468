.container {
  max-width: 1200px;
  margin: 7rem auto 0px auto;
  display: flex;
  flex-direction: column;
  animation: fadein 0.4s ease-out 1 forwards;
}
.containerFullScreen {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: fadein 0.4s ease-out 1 forwards;
}

.LoadingContainer {
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.blackBtn {
  padding: 0.8rem 1rem;
  font-family: le-monde-livre-std, "Times New Roman", Times, serif;
  font-style: normal;
  font-weight: 300;
  display: inline-block;
  border: none;
  background-color: $color-black;
  color: $color-white;
}

.transparentBtn {
  padding: 0.8rem 1rem;
  font-family: le-monde-livre-std, "Times New Roman", Times, serif;
  font-style: normal;
  font-weight: 300;
  border: 1px solid $color-black;
  background-color: transparent;
  color: $color-black;
  &:hover,
  &:focus {
    background-color: $color-black;
    color: $color-white;
  }
}

.headerSpace {
  height: 5.5rem;
}

@media screen and (max-width: 1200px) {
  .container {
    padding: 0 1rem;
    margin: 0 auto;
  }
  .containerFullScreen {
    padding: 0px 1.5rem 6rem 1.5rem;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
