@import "../../scss/abstarct/colors";

.golden {
  //Fix inherence
  font-family: le-monde-livre-std, "Times New Roman", Times, serif;
  font-style: normal;
  font-weight: 300;
  background-color: $color-gloden;
  color: $color-white;
  border: none;
  &:hover {
    background-color: $color-gloden-dark;
  }
}

.blackTransparent {
  //Fix inherence
  font-family: le-monde-livre-std, "Times New Roman", Times, serif;
  font-style: normal;
  font-weight: 300;
  background-color: transparent;
  color: $color-black;
  border: 2px solid $color-black;
  &:hover {
    color: $color-white;
    background-color: $color-black;
  }
}

.grey {
  //Fix inherence
  font-family: le-monde-livre-std, "Times New Roman", Times, serif;
  font-style: normal;
  font-weight: 300;
  background-color: transparent;
  color: $color-black;
  border: none;
  &:hover {
    background-color: $color-light-gray;
  }
}
