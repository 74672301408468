@import "../../scss/abstarct/colors";

.discounts {
  display: flex;
  flex-direction: row;
  padding-left: 0px;
  padding-bottom: 0.5rem;
  margin: 0 0 0.5rem 0;
  width: 100%;
  overflow-x: scroll;

  &:hover {
    &::-webkit-scrollbar-track {
      background-color: $color-white;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $color-normal-gray;
    }
  }

  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: $color-white;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color-white;
    &:hover {
      background: $color-black;
    }
  }
}

.emptyMsg {
  color: $color-dark-gray;
  margin-bottom: 2rem;
}

.placeholder {
  width: 15rem;
  height: 8rem;
  position: relative;
  background-color: $color-light-gray;
  overflow: hidden;
}

@keyframes shining {
  0% {
    transform: translateX(-130%);
  }
  100% {
    transform: translateX(130%);
  }
}

.animatedBackground {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shining;
  animation-timing-function: linear;
  background: linear-gradient(
    to right,
    rgba(255, 0, 0, 0) 0%,
    rgba(195, 195, 195, 0.2) 50%,
    rgba(255, 0, 0, 0) 100%
  );
  height: 100%;
  width: 90%;
  position: absolute;
}
