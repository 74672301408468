@import '../../scss/abstarct/colors';
.title {
  margin: 1rem 0px;
  padding-left: 0.8rem;
  position: relative;
  &::before {
    content: '';
    left: 0;
    top: calc(16%);
    position: absolute;
    width: 0.3rem;
    height: 60%;
    background-color: $color-gloden;
  }
}
