@import '../../scss/abstarct/colors';

.container {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
  padding: 0px 1rem 1rem;
  position: relative;
  background-color: transparent;
  &::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: calc(100% - 1.5rem);
    transform: translateY(2rem);
    border: 1px solid $color-black;
    background-color: transparent;
    pointer-events: none;
    z-index: -1;
  }

  h1 {
    padding: 0px 0.5rem;
    width: fit-content;
    background-color: $color-white;
  }
  .infoBlock {
    margin-bottom: 0.5rem;
    padding: 0.8rem 0.8rem;
    .header {
      display: flex;
      align-items: center;
      margin-bottom: 0.8rem;
      h3 {
        margin: 0px;
      }
      img {
        margin-right: 0.5rem;
        height: 1.5rem;
        width: auto;
      }
    }
    p {
      margin: 0px;
    }
  }
  .queueInfoBlock {
    background-color: $color-light-gray;
    img {
      animation: flashing 2s ease infinite normal both;
    }
  }
  .contactBtns {
    display: flex;
    a:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

@keyframes flashing {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 100;
  }
  100% {
    opacity: 0;
  }
}
