html {
  font-size: 16px;
  height: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.4;
  height: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  box-sizing: border-box;
}

#root {
  height: 100%;
}

h1 {
  font-family: le-monde-livre-std, 'Times New Roman', Times, serif;
  font-weight: 700;
  font-size: 1.5rem;
  color: $color-black;
}

h2 {
  font-family: le-monde-livre-std, 'Times New Roman', Times, serif;
  font-weight: 700;
  font-size: 1.2rem;
}

h3 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1rem;
}

p {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1rem;
}

form {
  input {
    font-family: 'Roboto', sans-serif;
  }
  input[type='submit'] {
    font-family: le-monde-livre-std, 'Times New Roman', Times, serif;
    font-style: normal;
    font-weight: 300;
    max-width: fit-content;
    padding: 0.7rem;
    display: inline-block;
  }
  input[type='button'] {
    font-family: le-monde-livre-std, 'Times New Roman', Times, serif;
    font-style: normal;
    font-weight: 300;
    max-width: fit-content;
    padding: 0.7rem;
    display: inline-block;
  }
  input[type='text'],
  input[type='password'],
  input[type='email'] {
    min-height: 2.5rem;
    padding: 0px 0.5rem;
    width: 100%;
    max-width: 30rem;
    border: none;
    background-color: $color-light-gray;
    &:focus {
      outline: 1px solid $color-dark-gray;
    }
  }
}

button {
  font-family: le-monde-livre-std, 'Times New Roman', Times, serif;
  font-style: normal;
  font-weight: 300;
  padding: 0.8rem 1rem;
  display: inline-block;
}

ul {
  font-family: 'Roboto', sans-serif;
}

a {
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  body,
  p,
  span {
    font-size: 0.9rem;
  }
  h1 {
    font-size: 1.2rem;
  }
  h2 {
    font-size: 1.2rem;
  }
}
