@import '../../scss/abstarct/colors';

.signInForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 350px;
  .label,
  input[type='text'] {
    margin-bottom: 1rem;
  }

  .passwordContainer {
    position: relative;
    width: 100%;
    .link {
      position: absolute;
      top: 0.6rem;
      right: 0.5rem;
    }
    input[type='password'] {
      margin-bottom: 1rem;
      padding-right: 4rem;
    }
  }
}

.link {
  color: $color-black;
  margin-left: 0.5rem;
}
