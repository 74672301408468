@import "../../scss/abstarct/colors";

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 30rem;
  p {
    margin-top: 0;
    margin-bottom: 2rem;
    width: 100%;
  }
  .label {
    margin-bottom: 1rem;
  }
  .sendSet {
    width: 100%;
    display: flex;
    margin-bottom: 0.5rem;
    input[type="text"] {
      min-height: 2.5rem;
      padding: 0px 0.5rem;
      margin-right: 1rem;
      flex: 1 1 20rem;
      border: none;
      background-color: $color-light-gray;
      &:focus {
        outline: 1px solid $color-dark-gray;
      }
    }
  }
}
.successMsgContainer {
  max-width: 30rem;
  overflow: hidden;
}
.link {
  color: $color-black;
  margin-left: 0.5rem;
}
.hidden {
  display: none;
}
