@import "../../scss/abstarct/colors";

.bar {
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: $color-black;
  z-index: 1;
  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      height: 3rem;
      width: auto;
      margin-left: 1rem;
    }
    .menu {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: stretch;
      width: 100%;
      height: 100%;
      max-width: 35rem;
      padding: 0px;
      margin: 0px 1rem 0px 0px;
      list-style-type: none;
      color: $color-white;
      transition: 0.4s all ease-in-out;
    }
    .oneItem {
      width: fit-content;
    }
  }
}

@media screen and (max-width: 800px) {
  .bar {
    bottom: 0px;
    top: unset;
    .container {
      display: block;
      .logo {
        display: none;
      }
      .menu {
        flex-direction: row;
        width: 100%;
        max-width: unset;
        margin: 0px;
        padding: 0px;
        margin: 0px;
      }
    }
  }
}
